// $primary: #a51616;
$primary: darkblue; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.logo {
	max-width: 350px;
	width: 100%;

	@media (max-width: 990px) {
		max-width: 250px;
	}

	@media (max-width: 767px) {
		max-width: 235px;
	}

	@media (max-width: 420px) {
		max-width: 225px;
	}
}
 
.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
    	font-family: 'Archivo Narrow', sans-serif;
		text-align: center;
		font-size: 24px;
		height: 40px;
		margin-top: 50px;
		border-radius: 10px;
		display: flex;
		align-items: center;

		@media (max-width: 990px) {
			height: 45px;
			font-size: 20px;
			margin-top: 30px;
		}
		@media (max-width: 767px) {
		    height: 45px;
		    margin-top: 5px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 80%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

@mixin btn3 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1em 4em;

	&:hover {
		text-decoration: none;
		background: limegreen;
		color: black;
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

a.btn3 {
	@include btn3;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.hiphopheader {
	background-image: url('../img/hiphopheader.jpg');
	background-size: cover;
	height: 100vh;

	@media (max-width: 767px) {
		background-position: 50% 30%;
	}
}

.hiphopcaption {
	font-family: 'Anton', sans-serif;
	font-size: 40px;
	color: white;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 40%;
	transform: translate(-25%, -10%);

	@media (max-width: 990px) {
		top: 40%;
	left: 30%;
	transform: translate(-25%, -10%);
	}
}

.title {
	text-align: center;
	font-family: 'Calligraffitti', cursive;
	font-size: 120px;

	@media (max-width: 990px) {
		font-size: 90px;
	}
}

.col-sm-3 {
	padding-left: 0px;
	padding-right: 0px;
}

.icontext {
	font-family: 'Archivo Narrow', sans-serif;
	font-size: 38px;
	text-align: center;
}

.iconp {
	padding-left: 30px;
	padding-right: 30px;
	text-align: center;
}

.music {
	padding: 80px 0;
	background: linear-gradient(rgba(255, 255, 255, 0.55), rgba(0, 0, 0, 0.75)),
    rgba(0,0,0,0.55) url('../img/hiphopbg.jpg') no-repeat center;
	background-size: cover;
}

.Headline {
	font-family: 'Calligraffitti', cursive;
	font-size: 60px;
	color: white;
}

.Headline2 {
	margin-top: 70px;
	position: relative;
	text-align: center;
	font-family: 'Calligraffitti', cursive;
	font-size: 60px;
	color: black;

	@media (max-width: 767px) {
		margin-top: 50px;
	}
}


.browser {
	text-align: center;
	padding: 20px;
}

.signup {
	font-family: 'Archivo Narrow', sans-serif;
	font-size: 21px;
	text-align: center;

	@media (max-width: 1200px) {
		font-size: 17px;
	}
}

.row {
	margin-left: 0px;
	margin-right: 0px;
}

.container-fluid {
	padding-left: 0px;
	padding-right: 0px;
}